import { SET_ICONS } from 'state/constants';

export const getIcons = async (dispatch, token) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ROOT}/api/family/icon`,
    {
      headers: {
        Authorization: `jwt ${token}`,
      },
    }
  );
  const json = await response.clone().json();
  if (response.status === 200) {
    dispatch({
      type: SET_ICONS,
      icons: json,
    });
  }
  return response;
};
