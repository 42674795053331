import { SET_NOTEBOOKS, SET_ENTRIES } from 'state/constants';

export const getNotebooks = async (dispatch, token) => {
  const result = await fetch(
    `${process.env.REACT_APP_API_ROOT}/api/family/notebook-enhanced`,
    {
      headers: {
        Authorization: `jwt ${token}`,
      },
    }
  );
  const json = await result.clone().json();

  if (result.status === 200) {
    dispatch({
      type: SET_NOTEBOOKS,
      notebooks: json,
    });
  }
  return result;
};

export const getEntries = async (dispatch, token) => {
  const result = await fetch(
    `${process.env.REACT_APP_API_ROOT}/api/family/entry`,
    {
      headers: {
        Authorization: `jwt ${token}`,
      },
    }
  );
  const entries = await result.clone().json();

  if (result.status === 200) {
    dispatch({
      type: SET_ENTRIES,
      entries,
    });
  }

  return result;
};
