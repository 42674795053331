import { useContext } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Entry from './Entry';
import StoreContext from 'state/context/store';
import styles from './index.module.scss';

const EntryList = ({ attachments, entries, selectedNotebook, setEntries }) => {
    const history = useHistory();
    const match = useRouteMatch();
    const [store] = useContext(StoreContext);
    const { me } = store;
    const { isTeacher, isResearcher } = me;

    const canAdd = () => {
        if (isResearcher) {
            return false;
        } else if (isTeacher && selectedNotebook === 'teacher') {
            return true;
        } else if (!isTeacher && selectedNotebook === 'family') {
            return true;
        }

        return false;
    }

    return (
        <div className={styles['entry-list-wrapper']}>
            <ul className={styles['entry-list']}>
                {entries
                    .sort(
                        (a, b) =>
                            new Date(b.created_zulu) - new Date(a.created_zulu)
                    )
                    .map((note) => (
                        <Entry
                            key={note.id}
                            {...note}
                            attachments={attachments}
                            setEntries={setEntries}
                            entries={entries}
                        />
                    ))}
                {entries.length === 0 && (
                    <div className={styles['no-entries']}>
                        No notebook entries yet.
                    </div>
                )}

                {canAdd() && (
                    <div
                        className={styles.add}
                        onClick={() => history.push(`${match.url}/new`)}
                    />
                )}
            </ul>
        </div>
    );
};

export default EntryList;
