import React from "react";

const Paperclip = () => {
    return (
        <svg width="11" height="21" viewBox="0 0 11 21" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.50001 4.5V15.5C9.50001 17.7002 7.70021 19.5 5.5 19.5C3.29984 19.5 1.50004 17.7002 1.50004 15.5V4.00003C1.50004 2.60011 2.60009 1.5 4.00001 1.5C5.39988 1.5 6.49998 2.60011 6.49998 4.00003V15.5C6.49998 16.0498 6.04975 16.5 5.49995 16.5C4.95016 16.5 4.49997 16.0498 4.49997 15.5V6H2.99998V15.5C3.00003 16.8999 4.10009 18 5.5 18C6.89991 18 8.00002 16.8999 8.00002 15.5V4.00003C8.00002 1.80005 6.20021 0 4.00001 0C1.7998 0 0 1.80005 0 4.00003V15.5C4.68747e-05 18.5498 2.45024 21 5.5 21C8.54976 21 11 18.5498 11 15.5V4.5H9.50001Z" />
        </svg>
    );
};

export default Paperclip;