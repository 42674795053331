import React, { useCallback, useContext, useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useHistory,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import StoreContext from 'state/context/store';
import Header from 'Components/Header';
import styles from './index.module.scss';
import EntryList from 'Pages/Notebooks/Notebook/EntryList';
import NewNote from 'Pages/Notebooks/Notebook/NewEntry';
import ExistingEntry from 'Pages/Notebooks/Notebook/ExistingEntry';
import StudentProfile from 'Pages/Notebooks/Notebook/StudentProfile';

const Notebook = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const [store] = useContext(StoreContext);
    const { params } = match;
    const notebookId = parseInt(params.notebookId, 10);
    const { notebook, me } = store;
    const { notebooks = [] } = notebook;
    const currentNotebook =
        notebooks.find((notebook) => notebook.id === notebookId) || {};
    const { student_name, student: studentId } = currentNotebook;
    const { authenticated, token } = me;

    const [selectedNotebook, setSelectedNotebook] = useState('family');
    const [entries, setEntries] = useState([]);
    const [family, setFamily] = useState([]);
    const [studentCase, setStudentCase] = useState({});
    const [attachments, setAttachments] = useState([]);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setSelectedNotebook(me.isTeacher ? 'teacher' : 'family');
    }, [me.isTeacher]);

    const fetchAttachments = useCallback(async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/family/attachment${
                notebookId ? `?notebook=${notebookId}` : ''
            }`,
            {
                headers: {
                    Authorization: `jwt ${token}`,
                },
            }
        );
        const attachments = await response.json();

        if (response.status === 200) {
            setAttachments(
                attachments.filter(
                    (attachment) => attachment.status === 'active'
                )
            );
        }
        return attachments;
    }, [notebookId, token]);

    const fetchEntries = useCallback(async () => {
        const response = await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/family/entry${
                notebookId ? `?notebook=${notebookId}` : ''
            }`,
            {
                headers: {
                    Authorization: `jwt ${token}`,
                },
            }
        );
        const entries = await response.json();

        if (response.status === 200) {
            setEntries(entries);
            return entries;
        }
    }, [notebookId, token]);

    const fetchFamily = useCallback(async () => {
        if (authenticated && studentId) {
            const response = await fetch(
                `${process.env.REACT_APP_API_ROOT}/api/family/family-user?student=${studentId}&is_archived=false`,
                {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                const result = await response.json();
                setFamily(
                    result?.length
                        ? result?.filter(
                              (person) =>
                                  person.is_active ||
                                  (person.not_activated &&
                                      !person.invitation_expired)
                          )
                        : []
                );
                return result;
            }
        }
    }, [authenticated, studentId, token]);

    const fetchCase = useCallback(async () => {
        if (authenticated && currentNotebook.case) {
            const response = await fetch(
                `${process.env.REACT_APP_API_ROOT}/api/v1/case/${currentNotebook.case}`,
                {
                    headers: {
                        Authorization: `JWT ${token}`,
                    },
                }
            );
            if (response.status === 200) {
                const result = await response.json();
                setStudentCase(result);
                return result;
            }
        }
    }, [authenticated, currentNotebook.case, token]);

    useEffect(() => {
        if (authenticated) {
            fetchEntries();
            fetchAttachments();
        }
    }, [authenticated, fetchEntries, fetchAttachments]);

    useEffect(() => {
        if (authenticated && currentNotebook.id && !loaded) {
            Promise.all([fetchFamily(), fetchCase(), fetchAttachments()])
                .then(([family]) => {
                    setLoaded(true);
                    if (
                        me.isTeacher &&
                        family.filter((member) => member.is_active).length === 0
                    ) {
                        history.replace(`/notebooks/${notebookId}/profile`);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [
        notebookId,
        authenticated,
        currentNotebook.id,
        fetchCase,
        fetchFamily,
        fetchAttachments,
        loaded,
        history,
        me.isTeacher,
    ]);

    useEffect(() => {
        if (notebookId) {
            fetch(
                `${process.env.REACT_APP_API_ROOT}/api/family/mark-notebook-read`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `jwt ${token}`,
                    },
                    body: JSON.stringify({
                        notebook: notebookId,
                    }),
                }
            );
        }
    }, [notebookId, token]);

    const profileImageSource = currentNotebook?.portrait_thumbnail
        ? `${process.env.REACT_APP_API_ROOT}${currentNotebook.portrait_thumbnail}?auth=${token}`
        : '';

    const filteredEntries = entries.filter(
        (entry) =>
            entry.author_type === selectedNotebook &&
            entry.notebook === notebookId
    );
    return (
        <div className={styles.container}>
            <Switch>
                {!authenticated && (
                    <Redirect
                        to={{ pathname: '/login', state: { from: location } }}
                    />
                )}
                <Route path={`${match.path}/profile`}>
                    <StudentProfile
                        notebook={currentNotebook}
                        family={family}
                        setFamily={setFamily}
                        studentCase={studentCase}
                        attachments={attachments}
                        setAttachments={setAttachments}
                        studentId={studentId}
                        name={student_name}
                    />
                </Route>
                <Route path={`${match.path}/new`}>
                    <Header
                        showBack={false}
                        showProfileArrow={false}
                        showPhoto={true}
                        photo={profileImageSource}
                    />
                    <NewNote
                        entries={entries}
                        setEntries={setEntries}
                        notebook={currentNotebook}
                        setUploadedAttachments={setAttachments}
                    />
                </Route>
                <Route exact path={`${match.path}/entry/:entryId`}>
                    <Header
                        showPhoto={true}
                        photo={profileImageSource}
                        showBack={false}
                        name={student_name}
                    />
                    <ExistingEntry
                        entries={entries}
                        setEntries={setEntries}
                        attachments={attachments}
                    />
                </Route>
                <Route exact path={`${match.path}/entry/:entryId/edit`}>
                    <Header
                        showBack={false}
                        showProfileArrow={false}
                        showPhoto={true}
                        photo={profileImageSource}
                    />
                    <NewNote
                        entries={entries}
                        setEntries={setEntries}
                        notebook={currentNotebook}
                        uploadedAttachments={attachments}
                        setUploadedAttachments={setAttachments}
                    />
                </Route>
                <Route path={match.path} exact>
                    <Header
                        showPhoto={true}
                        photo={profileImageSource}
                        showBack={notebooks?.length !== 1}
                        name={student_name}
                    />
                    <div className={styles['notebook-switcher-container']}>
                        <div className={styles['notebook-switcher']}>
                            <div
                                className={`${
                                    selectedNotebook === 'family'
                                        ? styles.active
                                        : ''
                                }`}
                                onClick={() => setSelectedNotebook('family')}
                            >
                                Family
                            </div>
                            <div
                                className={`${
                                    selectedNotebook === 'teacher'
                                        ? styles.active
                                        : ''
                                }`}
                                onClick={() => setSelectedNotebook('teacher')}
                            >
                                Teacher
                            </div>
                        </div>
                    </div>
                    <EntryList
                        selectedNotebook={selectedNotebook}
                        attachments={attachments}
                        setEntries={setEntries}
                        entries={filteredEntries}
                    />
                </Route>
            </Switch>
        </div>
    );
};

export default Notebook;
