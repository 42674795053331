import React, { useEffect, useContext, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import Icons from 'Components/Icons';
import BackButton from 'Components/BackButton';
import Entry from '../EntryList/Entry';
import StoreContext from 'state/context/store';
import styles from './index.module.scss';
import Attachment from 'Components/Attachment';
import EntryMenu from 'Components/EntryMenu';
import moodHappy from 'assets/images/mood-icons/mood-happy.svg';
import moodKindaHappy from 'assets/images/mood-icons/mood-kinda-happy.svg';
import moodKindaMad from 'assets/images/mood-icons/mood-kinda-mad.svg';
import moodMad from 'assets/images/mood-icons/mood-mad.svg';
import moodMedium from 'assets/images/mood-icons/mood-medium.svg';

const ExistingNote = ({ entries, setEntries, attachments }) => {
    const match = useRouteMatch();
    const history = useHistory();
    const [store] = useContext(StoreContext);
    const { me } = store;
    const { token, userId } = me;
    const [reply, setReply] = useState('');
    const [editing, setEditing] = useState();
    const notebookId = parseInt(match.params.notebookId, 10);
    const entryId = parseInt(match.params.entryId, 10);
    const entry = entries.find((entry) => entry.id === entryId) || {};
    const {
        author,
        created,
        icons = [],
        text,
        entry_set = [],
        author_type,
    } = entry;
    const datetime = DateTime.fromISO(created);
    const isTeacher = author_type === 'teacher';
    const indicatorImageLookup = [
        undefined,
        moodMad,
        moodKindaMad,
        moodMedium,
        moodKindaHappy,
        moodHappy,
    ];

    const [mood, setMood] = useState();
    const [sleep, setSleep] = useState();
    const [meals, setMeals] = useState();

    const entryAttachments = attachments.filter(
        (attachment) =>
            attachment.notebook === notebookId && attachment.entry === entryId
    );

    const onKeyPress = ({ key, target }) => {
        if (key === 'Enter' && target.value.length > 0) {
            saveReply();
        }
    };

    const saveReply = async () => {
        if (reply.trim().length) {
            const response = await fetch(
                `${process.env.REACT_APP_API_ROOT}/api/family/entry${
                    editing ? `/${editing}` : ''
                }`,
                {
                    method: Boolean(editing) ? 'PUT' : 'POST',
                    headers: {
                        Authorization: `jwt ${token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        notebook: notebookId,
                        user: userId,
                        text: reply.trim(),
                        parent_entry: entryId,
                    }),
                }
            );
            const result = await response.json();

            const updatedEntries = [...entries];
            const entryIdx = entries.findIndex((entry) => entry.id === entryId);
            const updatedEntrySet = [...entry_set];

            if (editing) {
                const entrySetIndex = updatedEntrySet.findIndex(
                    (entry) => entry.id === editing
                );
                updatedEntrySet[entrySetIndex] = result;
            } else {
                updatedEntrySet.push(result);
            }
            updatedEntries[entryIdx] = {
                ...updatedEntries[entryIdx],
                entry_set: updatedEntrySet,
            };

            setEntries(updatedEntries);
            setReply('');
            setEditing(null);
        }
    };

    const deleteReply = async (replyId) => {
        const response = await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/family/entry/${replyId}`,
            {
                method: 'PATCH',
                headers: {
                    Authorization: `jwt ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: 'deleted',
                    text: '',
                }),
            }
        );

        const result = await response.json();
        const updatedEntries = [...entries];
        const entryIdx = entries.findIndex((entry) => entry.id === entryId);
        const updatedEntrySet = [...entry_set];

        const entrySetIndex = updatedEntrySet.findIndex(
            (entry) => entry.id === replyId
        );
        updatedEntrySet[entrySetIndex] = {
            ...result,
            status: 'deleted',
            text: '',
        };

        updatedEntries[entryIdx] = {
            ...updatedEntries[entryIdx],
            entry_set: updatedEntrySet,
        };

        setEntries(updatedEntries);
    };

    const back = () => {
        history.replace(`/notebooks/${notebookId}`);
    };

    const deleteNote = async () => {
        fetch(`${process.env.REACT_APP_API_ROOT}/api/family/entry/${entryId}`, {
            method: 'PUT',
            headers: {
                Authorization: `jwt ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...entry,
                status: 'deleted',
            }),
        });

        const updatedEntries = entries.filter((entry) => entry.id !== entryId);

        setEntries(updatedEntries);
        back();
    };

    const editReply = (replyId) => {
        const reply = entry_set.find((entry) => entry.id === replyId);
        setReply(reply.text);
        setEditing(replyId);
    };

    const isImage = (filename) => {
        return filename.toLowerCase().match(/.(jpg|jpeg|png|gif|webp)$/i);
    };
    const imageAttachments = entryAttachments.filter((attachment) =>
        isImage(attachment.original_filename)
    );
    const documentAttachments = entryAttachments.filter(
        (attachment) => !isImage(attachment.original_filename)
    );
    const userOwnsEntry = entry.user === userId;

    useEffect(() => {
        if (entryId) {
            const entry = entries.find(
                (entry) =>
                    entry.notebook === notebookId &&
                    entry.id === parseInt(entryId, 10)
            );
            if (entry) {
                setMeals(entry.food);
                setMood(entry.mood);
                setSleep(entry.sleep);
            }
        }
    }, [notebookId, entries, entryId]);

    return (
        <div className={styles.container}>
            <span className={styles['header-divider']} />
            <div className={styles['entry-content']}>
                <div className={styles.header}>
                    <div className={styles['back-button-wrapper']}>
                        <BackButton onClick={back} />
                    </div>
                    <div className={styles.title}>
                        <h2
                            className={styles.author}
                            style={{
                                color: isTeacher
                                    ? 'rgba(214,142,36,1)'
                                    : 'rgba(7,189,135,1)',
                            }}
                        >
                            {author}
                        </h2>
                        <div className={styles.timestamp}>
                            {datetime.toFormat('DD, h:mma')}
                        </div>
                    </div>
                    <div className={styles['entry-actions']}>
                        <Icons
                            entry_set={entry_set}
                            icons={icons}
                            entryId={entry.id}
                            parent_entry={false}
                            isReply={false}
                            isExistingEntry={true}
                            attachments={attachments?.filter(
                                (attachment) => attachment.entry === entry.id
                            )}
                            setEntries={setEntries}
                            entries={entries}
                        />
                        {userOwnsEntry && (
                            <EntryMenu
                                editNote={() =>
                                    history.push(
                                        `/notebooks/${notebookId}/entry/${entryId}/edit`
                                    )
                                }
                                deleteNote={deleteNote}
                            />
                        )}
                    </div>
                </div>

                <div className={styles.scroll}>
                    {text && <div className={styles.note}>{text}</div>}
                    <div className={styles.likert}>
                        {Boolean(mood) && (
                            <span className={styles['selected-indicator']}>
                                Mood:{' '}
                                <img src={indicatorImageLookup[mood]} alt='' />
                            </span>
                        )}
                        {Boolean(meals) && (
                            <span className={styles['selected-indicator']}>
                                Food:{' '}
                                <img src={indicatorImageLookup[meals]} alt='' />
                            </span>
                        )}
                        {Boolean(sleep) && (
                            <span className={styles['selected-indicator']}>
                                Sleep:{' '}
                                <img src={indicatorImageLookup[sleep]} alt='' />
                            </span>
                        )}
                    </div>
                    {entryAttachments?.length > 0 && (
                        <div className={styles.attachments}>
                            <ul className={styles['image-attachments']}>
                                {imageAttachments.map((attachment) => (
                                    <Attachment
                                        key={attachment.id}
                                        name={attachment.original_filename}
                                        src={attachment.document_url}
                                    />
                                ))}
                            </ul>
                            <ul className={styles.documentAttachments}>
                                {documentAttachments.map((attachment) => (
                                    <Attachment
                                        key={attachment.id}
                                        name={attachment.original_filename}
                                        src={attachment.document_url}
                                    />
                                ))}
                            </ul>
                        </div>
                    )}
                    <div className={styles.replies}>
                        {entry_set.map((reply) => (
                            <Entry
                                setEntries={setEntries}
                                key={reply.id}
                                {...reply}
                                noBorder
                                editNote={() => editReply(reply.id)}
                                deleteNote={() => deleteReply(reply.id)}
                                onEntriesPage={false}
                            />
                        ))}
                    </div>
                </div>
            </div>
            {!me.isResearcher && (
                <div className={styles['reply-container']}>
                <input
                    className={styles['reply-input']}
                    value={reply}
                    onKeyPress={onKeyPress}
                    onChange={({ target }) => setReply(target.value)}
                    placeholder='Reply...'
                />

                    <button
                        className={`${styles['reply-button']} ${
                            reply.length ? styles.active : ''
                        }`}
                        onClick={saveReply}
                    ></button>
                </div>
            )}
        </div>
    );
};

export default ExistingNote;
