import { SET_TOKEN, LOGOUT, SET_ME } from 'state/constants';

const initialState = {
  userId: null,
  authenticated: false,
  token: null,
  firstName: '',
  lastName: '',
  email: '',
  isTeacher: null,
};

const MeReducer = (
  state = {
    ...initialState,
  },
  {
    type,
    id: userId,
    authenticated,
    token,
    first_name: firstName,
    last_name: lastName,
    email,
    groups,
  }
) => {
  switch (type) {
    case SET_ME:
      return {
        ...state,
        userId,
        firstName,
        lastName,
        email,
        isTeacher: groups?.includes('Teacher'),
        isResearcher: groups?.includes('Researcher'),
      };
    case SET_TOKEN:
      window.localStorage.setItem('token', token);
      return { ...state, token, authenticated: true };
    case LOGOUT:
      window.localStorage.removeItem('token');
      return { ...state, ...initialState };
    default:
      return state;
  }
};

export { MeReducer };
