import React from "react";

const DownloadPDF = () => {
    return (
        <svg width="24" height="30" viewBox="0 0 24 30" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.791992 8.5C0.791992 8.77615 1.01585 9 1.29199 9H1.99599C2.27213 9 2.49599 8.77615 2.49599 8.5V6.632C2.49599 6.35586 2.71985 6.132 2.99599 6.132H4.53599C6.26399 6.132 7.22399 4.968 7.22399 3.576C7.22399 2.172 6.27599 0.996002 4.53599 0.996002H1.29199C1.01585 0.996002 0.791992 1.21986 0.791992 1.496V8.5ZM5.48399 3.564C5.48399 4.236 4.97999 4.668 4.30799 4.668H2.99599C2.71985 4.668 2.49599 4.44414 2.49599 4.168V2.96C2.49599 2.68386 2.71985 2.46 2.99599 2.46H4.30799C4.97999 2.46 5.48399 2.904 5.48399 3.564Z"/>
            <path d="M8.64027 8.5C8.64027 8.77615 8.86413 9 9.14027 9H11.7963C14.3043 9 16.0443 7.416 16.0443 5.004C16.0443 2.592 14.3043 0.996002 11.7963 0.996002H9.14027C8.86413 0.996002 8.64027 1.21986 8.64027 1.496V8.5ZM10.8443 7.5C10.5681 7.5 10.3443 7.27615 10.3443 7V2.996C10.3443 2.71986 10.5681 2.496 10.8443 2.496H11.7963C13.4403 2.496 14.3043 3.588 14.3043 5.004C14.3043 6.36 13.3803 7.5 11.7963 7.5H10.8443Z"/>
            <path d="M17.6136 8.5C17.6136 8.77615 17.8374 9 18.1136 9H18.8176C19.0937 9 19.3176 8.77615 19.3176 8.5V6.164C19.3176 5.88786 19.5414 5.664 19.8176 5.664H22.6936C22.9697 5.664 23.1936 5.44014 23.1936 5.164V4.7C23.1936 4.42386 22.9697 4.2 22.6936 4.2H19.8176C19.5414 4.2 19.3176 3.97614 19.3176 3.7V2.96C19.3176 2.68386 19.5414 2.46 19.8176 2.46H22.7776C23.0537 2.46 23.2776 2.23615 23.2776 1.96V1.496C23.2776 1.21986 23.0537 0.996002 22.7776 0.996002H18.1136C17.8374 0.996002 17.6136 1.21986 17.6136 1.496V8.5Z"/>
            <path d="M6.70597 19.2326C6.59986 19.4692 6.64204 19.7464 6.81372 19.9401L11.7567 25.5207C12.0071 25.8031 12.4928 25.8031 12.7433 25.5207L17.6862 19.9401C17.8579 19.746 17.9001 19.4689 17.794 19.2326C17.6875 18.9964 17.4522 18.8441 17.1929 18.8441H14.2271V14.6591C14.2271 14.2953 13.9319 14 13.5681 14H10.9319C10.568 14 10.2728 14.2953 10.2728 14.6591V18.8438H7.30703C7.04769 18.8441 6.81241 18.9964 6.70597 19.2326Z"/>
            <path d="M3.18793 28.039C3.18793 28.585 3.63048 29.0276 4.17651 29.0276H20.3234C20.8695 29.0276 21.312 28.585 21.312 28.039C21.312 27.4929 20.8695 27.0504 20.3234 27.0504H4.17651C3.63048 27.0504 3.18793 27.4929 3.18793 28.039Z"/>
        </svg>
    );
};

export default DownloadPDF;