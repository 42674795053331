import { SET_ICONS } from 'state/constants';

const initialState = [];

const IconReducer = (state = initialState, { type, icons }) => {
  switch (type) {
    case SET_ICONS:
      return icons;
    default:
      return state;
  }
};

export { IconReducer };
