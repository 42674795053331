import { useRef, useState } from 'react';
import styles from './index.module.scss';
import moreIcon from 'assets/images/more-three-dots.svg';
import useOnClickOutside from '../../lib/useOnClickOutside';

const MenuButton = ({ editNote, deleteNote }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    const ref = useRef();
    useOnClickOutside(ref, () => setIsOpen(false));

    return (
        <div className={styles['menu-button']} onClick={toggle} ref={ref}>
            <img src={moreIcon} alt='Remove member' />
            {isOpen && (
                <div className={styles.menu}>
                    <span onClick={editNote}>Edit Note</span>
                    <span onClick={deleteNote}>Delete Note</span>
                </div>
            )}
        </div>
    );
};

export default MenuButton;
