import React, { useState } from "react";
import Header from "Components/Header";
import Member from "./Member";
import styles from "./index.module.scss";
import BackButton from "Components/BackButton";
import { useHistory } from "react-router-dom";

const Family = ({ name, studentId, token, family, setFamily }) => {
    const history = useHistory();

    const back = () => {
        if (history.length > 0) {
            history.goBack();
        } else {
            history.replace("/profile");
        }
    };

    const [person, setPerson] = useState("");
    const addPerson = async (e) => {
        e.preventDefault();
        if (person) {
            const response = await fetch(
                `${process.env.REACT_APP_API_ROOT}/api/family/add-family-user`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `jwt ${token}`,
                    },
                    body: JSON.stringify({
                        email: person,
                        student: studentId,
                    }),
                }
            );
            const result = await response.json();
            if (
                response.status === 200 &&
                !family.some((member) => member.email === person) // Don't re-add the user if they already exist
            ) {
                setFamily([...family, { email: person, id: result.id }]);
            }
            setPerson("");
        }
    };
    return (
        <div className={styles.container}>
            <Header name={name} showBack={false} showSubHeader={false} />
            <div className={styles["simple-subheader"]}>
                <div className={styles["back-button-wrapper"]}>
                    <BackButton onClick={back} />
                </div>
                <h1 className={styles.title}>Family</h1>
            </div>
            <div className={styles["family-invites"]}>
                <form className={styles.invite} onSubmit={addPerson}>
                    <div className={styles.row}>
                        <input
                            placeholder='Invite family...'
                            value={person}
                            onChange={({ target }) => setPerson(target.value)}
                        />
                        <button
                            className={`
                                ${styles["add-invite"]}
                                ${person.length ? "" : styles["disabled"]}
                            `}
                            type='submit'
                        />
                    </div>
                </form>
                <div className={styles.invites}>
                    {family.map((member) => (
                        <Member
                            key={member.email}
                            family={family}
                            studentId={studentId}
                            {...member}
                            token={token}
                            setFamily={setFamily}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Family;
