import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import StoreContext from 'state/context/store';
import { login } from 'state/actions/me';
import Box from 'Components/Box';

const Login = () => {
  const [, dispatch] = useContext(StoreContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    if (email && password) {
      const result = await login(dispatch, email, password);
      if (result.status === 400) {
        const json = await result.json();
        setError(
          json?.non_field_errors?.[0] ||
            json?.email?.[0] ||
            json?.password?.[0] ||
            'There was an unknown error'
        );
      }
      // Successful login routing handled in UserMonitor
    }
  };

  return (
    <Box>
      <form onSubmit={handleLogin}>
        <h2>Log In</h2>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onKeyDown={() => setError('')}
          onChange={({ target }) => setEmail(target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onKeyDown={() => setError('')}
          onChange={({ target }) => setPassword(target.value)}
        />
        <span style={{ color: 'red', fontSize: '13px' }}>{error}</span>
        <button onClick={handleLogin}>Log In</button>
        <Link to="/forgot-password">Forgot Password?</Link>
      </form>
    </Box>
  );
};

export default Login;
