import React from 'react';
import ibesttFamilyLogo from "assets/images/family-at-ibestt-logo.png";
import styles from './index.module.scss';

const Box = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.logo}>
          <img src={ibesttFamilyLogo} alt='family @ ibestt' />
        </div>
        {children}
      </div>
    </div>
  );
};

export default Box;
