import React, { useContext, useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory,
    Link,
} from 'react-router-dom';
import StoreContext from 'state/context/store';
import ChangePassword from './ChangePassword';
import Header from 'Components/Header';
import { updateMe, logout } from 'state/actions/me';
import styles from './index.module.scss';

const Profile = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const [store, dispatch] = useContext(StoreContext);
    const [error, setError] = useState('');
    const [newFirstName, setNewFirstName] = useState('');
    const [newLastName, setNewLastName] = useState('');
    const [newEmail, setNewEmail] = useState('');
    const { me } = store;
    const { authenticated, token, firstName, lastName, email } = me;

    useEffect(() => {
        setNewFirstName(firstName);
        setNewLastName(lastName);
        setNewEmail(email);
    }, [firstName, lastName, email]);
    const back = () => {
        if (history.location.state?.from) {
            history.push(history.location.state.from);
        } else {
            history.replace('/notebooks');
        }
    };

    const submit = async (e) => {
        e.preventDefault();
        if (!isSubmittable) return;
        if (!firstName || !lastName || !email) {
            setError('You must fill out all fields');
        } else {
            const response = await updateMe(dispatch, token, {
                first_name: newFirstName,
                last_name: newLastName,
                email: newEmail,
            });

            const result = await response.json();
            if (response.status !== 200) {
                if (
                    result.non_field_errors &&
                    result.non_field_errors.length > 0
                ) {
                    setError(result.non_field_errors);
                }
                setError('There was an error processing your request');
            } else {
                back();
            }
        }
    };

    const isSubmittable =
        newFirstName &&
        newLastName &&
        email &&
        (newFirstName !== firstName ||
            newLastName !== lastName ||
            newEmail !== email);

    return (
        <div className={styles.container}>
            <Switch>
                {!authenticated && (
                    <Redirect
                        to={{ pathname: '/login', state: { from: location } }}
                    />
                )}

                <Route path={`${match.path}/change-password`} exact>
                    <ChangePassword />
                </Route>

                <Route path={match.path} exact>
                    <Header
                        showBack={false}
                        showSubHeader={false}
                        showProfileArrow={false}
                    />
                    <div className={styles['simple-subheader']}>
                        <h1 className={styles.title}>My Profile</h1>
                        <div
                            className={styles.logout}
                            onClick={() => logout(dispatch, history)}
                        >
                            Log Out
                        </div>
                    </div>
                    <form onSubmit={submit}>
                        <div className={styles.row}>
                            <label>
                                <span className={styles['input-label']}>
                                    Name
                                </span>
                                <input
                                    placeholder='First Name'
                                    value={newFirstName}
                                    onChange={({ target }) =>
                                        setNewFirstName(target.value)
                                    }
                                />
                            </label>
                            <label>
                                <input
                                    placeholder='Last Name'
                                    value={newLastName}
                                    onChange={({ target }) =>
                                        setNewLastName(target.value)
                                    }
                                />
                            </label>
                        </div>
                        <div className={styles.row}>
                            <label>
                                <span className={styles['input-label']}>
                                    Email Address
                                </span>
                                <input
                                    placeholder='Email'
                                    value={newEmail}
                                    onChange={({ target }) =>
                                        setNewEmail(target.value)
                                    }
                                />
                            </label>
                        </div>
                        <div className={styles.row}>
                            <Link to='/profile/change-password'>
                                Change Password
                            </Link>
                        </div>
                        <div className={styles.row}>{error}</div>
                        <div className={styles['button-row']}>
                            <button
                                className={styles.cancel}
                                onClick={() => {
                                    history.goBack();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className={`
                  ${styles['save-button']}
                  ${isSubmittable ? '' : styles.disabled}
                `}
                                type='submit'
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </Route>
            </Switch>
        </div>
    );
};

export default Profile;
