import React from "react";

const CommentBubble = () => {
    return (
        <svg width="14" height="17" viewBox="0 0 14 17" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.03421 12.9658C12.2778 12.6748 14 10.5625 14 8C14 5.23858 12 3 7 3C2 3 0 5.23858 0 8C0 10.1228 1.1819 11.9366 4 12.662V17L8.03421 12.9658Z" />
        </svg>
    );
};

export default CommentBubble;