import React, { useContext, useEffect, useState } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useHistory,
    useRouteMatch,
    useLocation,
} from 'react-router-dom';
import StoreContext from 'state/context/store';
import Header from 'Components/Header';
import { getNotebooks } from 'state/actions/notebook';
import NotebookLink from './NotebookLink';
import Notebook from './Notebook';
import styles from './index.module.scss';

const Notebooks = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const [loaded, setLoaded] = useState(false);
    const [store, dispatch] = useContext(StoreContext);
    const { me, notebook } = store;
    const { authenticated, token } = me;
    const { notebooks } = notebook;

    useEffect(() => {
        if (authenticated) {
            getNotebooks(dispatch, token);
        }
    }, [dispatch, authenticated, token]);

    useEffect(() => {
        if (
            !loaded &&
            notebooks.length === 1 &&
            location.pathname === '/notebooks'
        ) {
            setLoaded(true);
            history.replace(`/notebooks/${notebooks[0].id}`);
        }
    }, [loaded, notebooks, history, location]);

    return (
        <div className={styles.container}>
            <Switch>
                {!authenticated && (
                    <Redirect
                        to={{ pathname: '/login', state: { from: location } }}
                    />
                )}

                <Route path={`${match.path}/:notebookId`}>
                    <Notebook />
                </Route>

                <Route path={match.path} exact>
                    <Header
                        showSubHeader={false}
                        showBack={false}
                        showName={false}
                        showProfileArrow={false}
                    />
                    <h1 className={styles.title}>Notebooks</h1>
                    <ul className={styles.notebooks}>
                        {notebooks.map((notebook) => (
                            <NotebookLink
                                key={notebook.id}
                                name={notebook.student_name}
                                hasNewEntries={notebook.new_entries_exist}
                                portrait={notebook.portrait_small_thumbnail}
                                id={notebook.id}
                                studentCase={notebook.case}
                                student={notebook.student}
                                token={token}
                            />
                        ))}
                    </ul>
                </Route>
            </Switch>
        </div>
    );
};

export default Notebooks;
