import { SET_ME, SET_TOKEN, LOGOUT } from 'state/constants';

export const login = async (dispatch, email, password) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_ROOT}/api/v1/api-token-auth`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    }
  );
  const json = await response.clone().json();
  if (response.status === 200) {
    const { token } = json;
    dispatch({
      type: SET_TOKEN,
      token,
    });
  }

  return response;
};

export const getMe = async (dispatch, token) => {
  const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/v1/me`, {
    headers: {
      Authorization: `jwt ${token}`,
    },
  });
  const json = await response.clone().json();
  if (response.status === 200) {
    dispatch({
      type: SET_ME,
      ...json,
    });
  } else if (response.status === 401) {
    dispatch({
      type: LOGOUT,
    });
  }
  return response;
};

export const logout = (dispatch, history) => {
  dispatch({
    type: LOGOUT,
  });
  history.push('/login');
};

export const updateMe = async (dispatch, token, profile) => {
  const response = await fetch(`${process.env.REACT_APP_API_ROOT}/api/v1/me`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `jwt ${token}`,
    },
    body: JSON.stringify(profile),
  });
  const json = await response.clone().json();
  if (response.status === 200) {
    const { token } = json;
    if (token) {
      dispatch({ type: SET_TOKEN, token });
    }
    dispatch({
      type: SET_ME,
      ...json,
    });
  }
  return response;
};

export const setToken = (dispatch, token) => {
  return dispatch({
    type: SET_TOKEN,
    token: token,
  });
};
