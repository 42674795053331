import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import StoreContext from 'state/context/store';
import { getMe, updateMe } from 'state/actions/me';
import { getNotebooks } from 'state/actions/notebook';
import Box from 'Components/Box';
import styles from './index.module.scss';

const Register = () => {
    const history = useHistory();
    const match = useRouteMatch();
    const { token } = match.params;
    const [store, dispatch] = useContext(StoreContext);
    const { notebook } = store;
    const { notebooks } = notebook;
    const currentNotebook = notebooks?.[0] || {};
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [jwt, setJwt] = useState('');
    useEffect(() => {
        (async () => {
            const response = await fetch(
                `${process.env.REACT_APP_API_ROOT}/api/v1/activateuser/${token}`
            );
            const result = await response.json();
            setJwt(result.jwt);
        })();
    }, [token]);

    useEffect(() => {
        if (jwt) {
            getMe(dispatch, jwt);
            getNotebooks(dispatch, jwt);
        }
    }, [dispatch, jwt]);

    const handleRegister = async (e) => {
        e.preventDefault();
        if (email && password && lastName && firstName) {
            const response = await updateMe(dispatch, jwt, {
                first_name: firstName,
                last_name: lastName,
                email,
                password,
            });

            if (response.status === 200) {
                history.replace('/');
            }
        }
    };
    console.log('token', token);
    console.log('currentNotebook', currentNotebook);
    console.log('email', email);
    return (
        <Box>
            <form onSubmit={handleRegister}>
                <h2>Accept Registration</h2>

                {currentNotebook.student_name === undefined && (
                    <>
                        <p>
                            The registration link you used has expired. Please
                            ask a family member or a teacher to resend a link.
                        </p>
                    </>
                )}

                {currentNotebook.student_name !== undefined && (
                    <>
                        {Boolean(currentNotebook.portrait_thumbnail) && (
                            <img
                                className={styles.familyImage}
                                src={`${process.env.REACT_APP_API_ROOT}${currentNotebook.portrait_thumbnail}?auth=${jwt}`}
                                alt={`${currentNotebook.student_name}'s family portrait`}
                            />
                        )}
                        <p>{`${currentNotebook.student_name}'s Family Notebook`}</p>
                        <input
                            type='text'
                            placeholder='First Name'
                            value={firstName}
                            onChange={({ target }) =>
                                setFirstName(target.value)
                            }
                        />
                        <input
                            type='text'
                            placeholder='Last Name'
                            value={lastName}
                            onChange={({ target }) => setLastName(target.value)}
                        />
                        <input
                            autoComplete='false'
                            type='text'
                            placeholder='Email'
                            value={email}
                            onChange={({ target }) => setEmail(target.value)}
                        />
                        <input
                            autoComplete='false'
                            type='password'
                            placeholder='Password'
                            value={password}
                            onChange={({ target }) => setPassword(target.value)}
                        />
                        <button onClick={handleRegister}>Create Account</button>
                        <Link to='/login'>Back to Log In page</Link>
                    </>
                )}
            </form>
        </Box>
    );
};

export default Register;
