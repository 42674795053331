import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import StoreContext from 'state/context/store';
import Box from 'Components/Box';

const ForgotPassword = () => {
  const [, dispatch] = useContext(StoreContext);
  const [email, setEmail] = useState('');

  const handlePasswordResetRequest = async (e) => {
    e.preventDefault();

    const apiPlaceholder = new Promise((resolve) => {
      resolve();
    });

    if (email) {
      await apiPlaceholder(dispatch, email);

      // TODO Validate 200 or show error
      // TODO what to do on success?
    }
  };

  return (
    <Box>
      <form onSubmit={handlePasswordResetRequest}>
        <h2>Reset your password</h2>
        <p>
          Enter the email address that you used to register and we'll send you a
          6-digit code to reset your password.
        </p>
        <input
          type="text"
          placeholder="Email"
          value={email}
          onChange={({ target }) => setEmail(target.value)}
        />
        <button onClick={handlePasswordResetRequest}>Send Code</button>
        <Link to="/login">Back to Log In page</Link>
      </form>
    </Box>
  );
};

export default ForgotPassword;
