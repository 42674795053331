import React from "react";

const ThumbsUp = () => {
    return (
        <svg width="21" height="20" viewBox="0 0 21 20" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.83817 9.51189C5.00885 9.49432 6.09768 9.23568 7.17776 7.10377C8.0464 5.3862 8.42341 0.0828121 10.1047 0.00245775C12.973 -0.138162 12.9526 5.7955 12.0152 7.49801C12.7998 7.53819 15.5 7.5 17.5 8C18.5009 8.25023 19.535 8.61795 19.4993 9.75798C19.4789 10.4711 18.9389 11.0436 18.4523 11.4203C18.6969 11.6538 18.9287 11.9979 19.0688 12.4976C19.3516 13.497 18.5288 14.1398 17.869 14.4838C18.0295 14.7174 18.1798 15.0061 18.2511 15.3426C18.3988 16.0432 17.6754 16.6358 17.1048 16.9874C17.2602 17.2309 17.4079 17.5549 17.4843 17.9792C17.7238 19.3001 16.338 19.9454 16.3329 19.9479C16.2438 19.9906 16.1495 20.0057 16.0578 19.9981L6.61877 19.6315C6.52197 19.629 6.43027 19.5989 6.35385 19.5512L4.63948 18.4815C4.47136 18.376 4.38475 18.1952 4.39239 18.0144L4.34908 10.0367C4.34653 9.76049 4.56306 9.53449 4.83817 9.51189Z" />
            <path d="M1.03494 9.63495H3.22312C3.51607 9.63495 3.75552 9.86848 3.75552 10.1598V17.7934C3.75552 18.0847 3.51607 18.3208 3.22312 18.3208H1.03494C0.739451 18.3208 0.5 18.0847 0.5 17.7934V10.1598C0.5 9.86848 0.739451 9.63495 1.03494 9.63495Z" />
        </svg>
    );
};

export default ThumbsUp;