import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import CloseX from 'Components/SvgIcons/CloseX/close-x';

const Member = ({
    email,
    can_reinvite = true,
    invitation_expired,
    not_activated = true,
    is_active = false,
    is_archived = false,
    id,
    token,
    family,
    setFamily,
    studentId,
}) => {
    const accepted = is_active && !is_archived && !not_activated;
    const pending = !accepted && !invitation_expired && not_activated;
    const [confirmRemove, setConfirmRemove] = useState(false);
    const [resent, setResent] = useState(false);
    const canResend = pending && can_reinvite && !resent;
    useEffect(() => {
        const timer = window.setTimeout(() => setResent(false), 1500);
        return () => window.clearTimeout(timer);
    }, [resent]);

    const reinvite = async () => {
        setResent(true);
        await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/family/family-user/${id}`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `jwt ${token}`,
                },
                body: JSON.stringify({
                    trigger_reinvite: true,
                }),
            }
        );
    };

    const removeMember = async () => {
        // API Here
        fetch(`${process.env.REACT_APP_API_ROOT}/api/v1/student/${studentId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `jwt ${token}`,
            },
            body: JSON.stringify({
                family_members: family
                    .filter((member) => member.id !== id)
                    .map((member) => member.id),
            }),
        });

        setFamily((family) => family.filter((member) => member.id !== id));
    };

    return (
        <div className={styles.container}>
            <p title={email} className={styles.email}>
                {email}
            </p>
            <div className={styles.actions}>
                <div className={styles.status}>
                    {accepted && (
                        <span className={styles.active}>Accepted</span>
                    )}
                    {canResend && (
                        <span className={styles.resend} onClick={reinvite}>
                            Resend
                        </span>
                    )}
                    {resent && (
                        <span className={styles.resent}>Sending...</span>
                    )}

                    {pending && <span className={styles.pending}>Pending</span>}
                </div>
                <div
                    className={styles.remove}
                    onClick={() => setConfirmRemove(true)}
                >
                    <CloseX />
                </div>
            </div>
            {confirmRemove && (
                <div className={styles['modal-wrapper']}>
                    <div className={styles['confirm-remove']}>
                        <div
                            onClick={() => setConfirmRemove(false)}
                            className={styles.remove}
                        >
                            <CloseX />
                        </div>
                        <p>Are you sure you want to remove this member?</p>
                        <div className={styles['button-row']}>
                            <button
                                className={styles.cancel}
                                onClick={() => setConfirmRemove(false)}
                            >
                                Cancel
                            </button>
                            <button onClick={removeMember}>Confirm</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Member;
