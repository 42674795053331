import { SET_NOTEBOOKS, ADD_ENTRY, SET_ENTRIES } from 'state/constants';

const initialState = {
  notebooks: [],
  entries: [],
};

const NotebookReducer = (
  state = {
    ...initialState,
  },
  { type, notebooks, entries, entry }
) => {
  switch (type) {
    case SET_NOTEBOOKS:
      return {
        ...state,
        notebooks: notebooks.filter((notebook) => notebook.status === 'active'),
      };
    case SET_ENTRIES:
      return { ...state, entries };
    case ADD_ENTRY:
      return { ...state, entries: [entry, ...state.entries] };
    default:
      return state;
  }
};

export { NotebookReducer };
