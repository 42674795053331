import React from "react";

const Download = () => {
    return (
        <svg width="19" height="16" viewBox="0 0 19 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.70597 5.23264C3.59986 5.46924 3.64204 5.74638 3.81372 5.94014L8.75666 11.5207C9.0071 11.8031 9.49283 11.8031 9.74327 11.5207L14.6862 5.94014C14.8579 5.74605 14.9001 5.46891 14.794 5.23264C14.6875 4.99637 14.4522 4.84413 14.1929 4.84413H11.2271V0.659058C11.2271 0.295258 10.9319 0 10.5681 0H7.93185C7.56805 0 7.27279 0.295258 7.27279 0.659058V4.8438H4.30703C4.04769 4.84413 3.81241 4.99637 3.70597 5.23264Z" />
            <path d="M0.187927 14.039C0.187927 14.585 0.630485 15.0276 1.17651 15.0276H17.3234C17.8695 15.0276 18.312 14.585 18.312 14.039C18.312 13.4929 17.8695 13.0504 17.3234 13.0504H1.17651C0.630485 13.0504 0.187927 13.4929 0.187927 14.039Z" />
        </svg>
    );
};

export default Download;