import Download from "Components/SvgIcons/Download/download";
import { useContext } from "react";
import StoreContext from "state/context/store";
import styles from "./index.module.scss";

const Attachment = ({ src, name }) => {
    const [store] = useContext(StoreContext);
    const { me } = store;
    const { token } = me;

    const isImage = (filename) => {
        return filename.toLowerCase().match(/.(jpg|jpeg|png|gif|webp)$/i);
    };

    return (
        <li>
            {Boolean(isImage(src)) && (
                <div className={styles["image-container"]}>
                    <img
                        className={styles.attachmentImage}
                        src={`${process.env.REACT_APP_API_ROOT}${src}?auth=${token}`}
                        alt={name}
                    />
                </div>
            )}
            {!Boolean(isImage(src)) && (
                <div className={styles.attachment}>
                    <div className={styles.name}>{name}</div>
                    <span
                        className={styles["download-attachment"]}
                        onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_API_ROOT}${src}?auth=${token}`
                            )
                        }
                    >
                        <Download />
                    </span>
                </div>
            )}
        </li>
    );
};

export default Attachment;
