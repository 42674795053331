// @flow
import { combineReducers } from 'redux';
import { MeReducer } from './me';
import { NotebookReducer } from './notebook';
import { IconReducer } from './icons';
import { RESET_APP } from 'state/constants';

const allReducers = combineReducers({
  me: MeReducer,
  notebook: NotebookReducer,
  icons: IconReducer,
});

const rootReducer = (state, action) => {
  if (action.type === RESET_APP) {
    state = undefined;
  }
  return allReducers(state, action);
};

export default rootReducer;
