import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import Box from 'Components/Box';

const PreRegister = () => {
    const match = useRouteMatch();
    const { token } = match.params;

    return (
        <Box>
            <form>
                <h2>Accept Registration</h2>

                <Link to={`/register/${token}`}>
                    <button>Continue</button>
                </Link>

                <Link to='/login'>Back to Log In page</Link>
            </form>
        </Box>
    );
};

export default PreRegister;
