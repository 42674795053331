import React, { useContext } from 'react';
import StoreContext from 'state/context/store';
import styles from './index.module.scss';
import Paperclip from 'Components/SvgIcons/Paperclip/paperclip';
import ThumbsUp from 'Components/SvgIcons/ThumbsUp/thumbs-up';
import CommentBubble from 'Components/SvgIcons/CommentBubble/comment-bubble';

const Icons = ({
    attachments,
    entryId,
    isReply,
    isExistingEntry,
    entry_set,
    icons: selectedIconEntryUsers,
    parent_entry,
    setEntries,
    onEntriesPage = false,
    entries = [],
}) => {
    const [store] = useContext(StoreContext);
    const { icons, me } = store;
    const { userId, token } = me;
    const attachmentCount = attachments?.filter(
        (attachment) =>
            !attachment.is_student_portrait && attachment.entry === entryId
    )?.length;
    const commentsCount = entry_set?.length || 0;

    const toggleIcon = async (iconId) => {
        // console.log('userId', userId);

        const alreadyClicked = selectedIconEntryUsers.some(
            (iconClick) => iconClick.user === userId
        );
        // console.log('alreadyClicked', alreadyClicked);

        let body = {};
        if (alreadyClicked) {
            // Remove icon
            const iconEntryUserId = selectedIconEntryUsers.find(
                (thisIcon) =>
                    thisIcon.icon === iconId && thisIcon.user === userId
            )?.icon_entry_user;

            // console.log('selectedIconEntryUsers', selectedIconEntryUsers);
            // console.log(
            //     'selectedIconEntryUser',
            //     selectedIconEntryUsers.find(
            //         (thisIcon) =>
            //             thisIcon.icon === iconId && thisIcon.user === userId
            //     )
            // );

            fetch(
                `${process.env.REACT_APP_API_ROOT}/api/family/icon-entry-user/${iconEntryUserId}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `jwt ${token}`,
                    },
                }
            );
        } else {
            body = {
                icon: iconId,
                entry: entryId,
                user: userId,
            };
            const response = await fetch(
                `${process.env.REACT_APP_API_ROOT}/api/family/icon-entry-user`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `jwt ${token}`,
                    },
                    body: JSON.stringify(body),
                }
            );

            if (response?.status !== 201) {
                console.log('error');
            } else {
                const result = await response.json();
                body = {
                    ...body,
                    icon_entry_user: result.id,
                };
            }
        }

        const updateEntries = (entries) => {
            const updatedEntries = [...entries];
            // console.log('body after post', body);

            if (isReply) {
                const parentIndex = updatedEntries.findIndex(
                    (entry) => entry.id === parent_entry
                );
                const replyIndex = updatedEntries[
                    parentIndex
                ].entry_set.findIndex((reply) => reply.id === entryId);
                updatedEntries[parentIndex].entry_set = [
                    ...updatedEntries[parentIndex].entry_set,
                ];
                updatedEntries[parentIndex].entry_set[replyIndex] = {
                    ...updatedEntries[parentIndex].entry_set[replyIndex],
                };
                if (alreadyClicked) {
                    const iconEntryUserId = selectedIconEntryUsers.find(
                        (thisIcon) =>
                            thisIcon.icon === iconId && thisIcon.user === userId
                    )?.icon_entry_user;
                    // console.log(
                    //     'updateEntries iconEntryUserId',
                    //     iconEntryUserId
                    // );

                    updatedEntries[parentIndex].entry_set[
                        replyIndex
                    ].icons = updatedEntries[parentIndex].entry_set[
                        replyIndex
                    ].icons.filter(
                        (i) => i.icon_entry_user !== iconEntryUserId
                    );
                } else {
                    const filteredUpdatedEntriesIcons = updatedEntries[
                        parentIndex
                    ].entry_set[replyIndex].icons.filter(
                        (i) => i.icon_entry_user === userId
                    );
                    // console.log(
                    //     'filteredUpdatedEntriesIcons',
                    //     filteredUpdatedEntriesIcons
                    // );
                    const alreadyPresentWeirdError =
                        filteredUpdatedEntriesIcons.length > 0;
                    // console.log(
                    //     'alreadyPresentWeirdError',
                    //     alreadyPresentWeirdError
                    // );
                    if (!alreadyPresentWeirdError) {
                        updatedEntries[parentIndex].entry_set[
                            replyIndex
                        ].icons = [
                            ...updatedEntries[parentIndex].entry_set[replyIndex]
                                .icons,
                            body,
                        ];
                    }
                }
            } else {
                const entryIndex = updatedEntries.findIndex(
                    (entry) => entry.id === entryId
                );
                // console.log(
                //     'updatedEntries[entryIndex].icons - before processing',
                //     updatedEntries[entryIndex].icons
                // );
                if (alreadyClicked) {
                    const iconEntryUserId = selectedIconEntryUsers.find(
                        (thisIcon) =>
                            thisIcon.icon === iconId && thisIcon.user === userId
                    )?.icon_entry_user;
                    // console.log(
                    //     'updateEntries iconEntryUserId',
                    //     iconEntryUserId
                    // );

                    updatedEntries[entryIndex].icons = updatedEntries[
                        entryIndex
                    ].icons.filter(
                        (i) => i.icon_entry_user !== iconEntryUserId
                    );
                    // console.log(
                    //     'updatedEntries[entryIndex].icons -- gafter processing unlike',
                    //     updatedEntries[entryIndex].icons
                    // );
                } else {
                    // not clicked
                    // double check because of strange error that there is only
                    // 1 element belonging to clicking user.
                    // filter icons by clicking user.
                    const filteredUpdatedEntriesIcons = updatedEntries[
                        entryIndex
                    ].icons.filter((i) => i.icon_entry_user === userId);
                    // console.log(
                    //     'filteredUpdatedEntriesIcons',
                    //     filteredUpdatedEntriesIcons
                    // );
                    // console.log(
                    //     'filteredUpdatedEntriesIcons.length',
                    //     filteredUpdatedEntriesIcons.length
                    // );
                    const alreadyPresentWeirdError =
                        filteredUpdatedEntriesIcons.length > 0;
                    // console.log(
                    //     'alreadyPresentWeirdError',
                    //     alreadyPresentWeirdError
                    // );
                    if (!alreadyPresentWeirdError) {
                        updatedEntries[entryIndex].icons = [
                            ...updatedEntries[entryIndex].icons,
                            body,
                        ];
                    }
                    // console.log(
                    //     'updatedEntries[entryIndex].icons -- after processing like',
                    //     updatedEntries[entryIndex].icons
                    // );
                }
            }

            return updatedEntries;
        };
        if (entries !== null) {
            setEntries(updateEntries);
        }
    };

    // there is a weird error, trying to deal with that.
    const userThumbs = selectedIconEntryUsers.filter(
        (selectedIcon) =>
            selectedIcon.icon === 1 && selectedIcon.user === userId
    );
    const omega = userThumbs.length > 1 ? 1 : 0;
    // console.log('userThumbs', userThumbs);
    // console.log('selectedIconEntryUsers', selectedIconEntryUsers);

    return (
        <div
            className={`${styles.icons} ${
                isExistingEntry ? styles['existing-entry'] : ''
            }`}
        >
            {Boolean(attachmentCount) && (
                <div
                    className={`
                        ${styles.icon}
                        ${styles.paperclip}
                    `}
                >
                    <Paperclip />
                    <span className={styles['icon-count']}>
                        {attachmentCount}
                    </span>
                </div>
            )}

            {!isReply && commentsCount > 0 && (
                <div
                    className={`
                        ${styles.icon}
                        ${styles['comment-bubble']}
                    `}
                >
                    <CommentBubble />
                    <span className={styles['icon-count']}>
                        {commentsCount}
                    </span>
                </div>
            )}

            {icons.map((icon) => {
                const iconCount =
                    selectedIconEntryUsers.filter(
                        (selectedIcon) => selectedIcon.icon === icon.id
                    ).length - omega || 0;
                const userSelected = selectedIconEntryUsers.some(
                    (icon) => icon.user === userId
                );
                return (
                    <React.Fragment key={icon.name}>
                        {icon.name === 'thumb' &&
                            (!onEntriesPage || iconCount > 0) && (
                                <div
                                    key={icon.name}
                                    onClick={() => {
                                        !onEntriesPage && !me.isResearcher && toggleIcon(icon.id);
                                    }}
                                    className={`
                                    ${styles.icon}
                                    ${styles['thumbs-up']}
                                    ${me.isResearcher ? styles['no-pointer'] : ''}
                                    ${userSelected ? styles.selected : ''}
                                `}
                                >
                                    <ThumbsUp />
                                    <span className={styles['icon-count']}>
                                        {iconCount}
                                    </span>
                                </div>
                            )}
                    </React.Fragment>
                );
            })}
        </div>
    );
};

export default Icons;
