import React, { useContext, useRef, useState } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { DateTime } from 'luxon';
import Header from 'Components/Header';
import Family from './Family';
import StoreContext from 'state/context/store';
import { getNotebooks } from 'state/actions/notebook';
import styles from './index.module.scss';
import DownloadPDF from 'Components/SvgIcons/DownloadPDF/download-pdf';
import PlusSmall from 'Components/SvgIcons/PlusSmall/plus-small';
import Download from 'Components/SvgIcons/Download/download';
import CloseX from 'Components/SvgIcons/CloseX/close-x';

const StudentProfile = ({
    name,
    notebook,
    family,
    setFamily,
    studentCase,
    studentId,
    attachments,
    setAttachments,
}) => {
    const match = useRouteMatch();
    const history = useHistory();
    const [store, dispatch] = useContext(StoreContext);
    const { me } = store;
    const { token, userId, isResearcher } = me;
    const [confirmDelete, setConfirmDelete] = useState(false);

    const photoUploadRef = useRef();
    const [photoError, setPhotoError] = useState('');
    const formatDate = (datetime) => {
        const date = DateTime.fromISO(datetime);
        const now = DateTime.local();
        const diff = date.diff(now.startOf('day'), 'days').as('days');
        if (diff < 1 && diff > 0) {
            return `Today, ${date.toFormat('h:mma')}`;
        } else if (diff < 0 && diff > -1) {
            return `Yesterday, ${date.toFormat('h:mma')}`;
        } else if (diff < 0 && diff > -6) {
            return `${date.toFormat('EEEE, h:mma')}`;
        }
        return date.toFormat('DD, h:mma');
    };
    const deleteNotebook = async () => {
        await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/family/notebook-enhanced/${notebook.id}`,
            {
                method: 'PATCH',
                headers: {
                    Authorization: `jwt ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: 'deleted',
                }),
            }
        );
        getNotebooks(dispatch, token);
        history.replace('/');
    };

    const uploadPhoto = async ({ target }) => {
        setPhotoError('');
        const { files } = target;
        const file = files?.[0];

        if (file) {
            if (file.size > 1e7) {
                // ~10mb
                setPhotoError('Image size limit is 10MB');
            } else {
                const formData = new FormData();
                formData.append('document', file);
                formData.append('user', userId);
                formData.append('notebook', notebook.id);
                formData.append('is_student_portrait', true);

                const response = await fetch(
                    `${process.env.REACT_APP_API_ROOT}/api/family/attachment`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `jwt ${token}`,
                        },
                        body: formData,
                    }
                );

                if (response?.status !== 201) {
                    setPhotoError('There was an error uploading your photo');
                } else {
                    const result = await response.json();
                    setAttachments((attachments) => [...attachments, result]);
                    getNotebooks(dispatch, token);
                }
            }
        }
    };

    const isImage = (uri) => {
        return uri.toLowerCase().match(/.(jpg|jpeg|png|gif|webp)$/i);
    };

    const profileImageSource = notebook.portrait_thumbnail;
    const attachmentCount = attachments?.filter(
        (attachment) => !attachment.is_student_portrait
    )?.length;

    return (
        <Switch>
            <Route path={`${match.path}/family`}>
                <Family
                    name={name}
                    studentId={studentId}
                    family={family}
                    setFamily={setFamily}
                    token={token}
                />
            </Route>

            <Route path={`${match.path}`} exact>
                <div className={styles['student-profile-container']}>
                    <Header
                        name={name}
                        showBack={true}
                        showName={true}
                        showProfileArrow={false}
                    />

                    <section className={styles.photoContainer}>
                        {Boolean(profileImageSource) && (
                            <img
                                src={`${process.env.REACT_APP_API_ROOT}${profileImageSource}?auth=${token}`}
                                alt={name}
                            />
                        )}
                        {!Boolean(profileImageSource) && (
                            <div className={styles.placeholderImage} />
                        )}
                        {!me.isTeacher && !me.isResearcher && (
                            <>
                                <label>
                                    <input
                                        type='file'
                                        ref={photoUploadRef}
                                        style={{ display: 'none' }}
                                        onChange={uploadPhoto}
                                        accept='image/*'
                                    />
                                    <div className={styles.edit}>
                                        Edit Photo
                                    </div>
                                </label>
                                <div className={styles.error}>{photoError}</div>
                            </>
                        )}
                    </section>

                    <section className={styles['family-teachers']}>
                        <div className={styles.row}>
                            <div className={styles.column}>
                                <h2 className={styles['family-header']}>
                                    Family ({family.length})
                                </h2>
                                <ul>
                                    {family.map((person) => (
                                        <li
                                            className={styles.person}
                                            key={person.id}
                                        >
                                            {(Boolean(person.first_name) ||
                                                Boolean(person.last_name)) && (
                                                <>
                                                    {person.first_name}{' '}
                                                    {person.last_name}
                                                </>
                                            )}
                                            {!(
                                                Boolean(person.first_name) ||
                                                Boolean(person.last_name)
                                            ) && <>{person.email}</>}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className={styles.column}>
                                <h2 className={styles['teachers-header']}>
                                    Teachers ({notebook.teacher_names?.length})
                                </h2>
                                <ul>
                                    {notebook.teacher_names?.map((teacher) => (
                                        <li
                                            className={styles.person}
                                            key={teacher}
                                        >
                                            {teacher}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {! isResearcher && (
                            <div className={styles.row}>
                                <button
                                    className={styles.add}
                                    onClick={() =>
                                        history.push(`${match.url}/family`)
                                    }
                                >
                                    <PlusSmall />
                                    Add Family
                                </button>
                            </div>
                        )}
                    </section>
                    {Boolean(notebook.fbasummary_modified_zulu) && (
                        <section className={styles.progressContainer}>
                            <h2 className={styles['student-progress']}>
                                Student Progress
                            </h2>

                            <div
                                className={`${styles.document} ${
                                    Boolean(notebook.fbasummary_modified_zulu)
                                        ? styles.active
                                        : ''
                                }`}
                            >
                                <div className={styles['document-info']}>
                                    <div className={styles.title}>
                                        FBA Summary
                                    </div>
                                    <div className={styles.modified}>
                                        {Boolean(
                                            notebook.fbasummary_modified_zulu
                                        ) && (
                                            <span className={styles.date}>
                                                <strong>Last update:</strong>{' '}
                                                {formatDate(
                                                    notebook.fbasummary_modified_zulu
                                                )}
                                            </span>
                                        )}
                                        {!Boolean(
                                            notebook.fbasummary_modified_zulu
                                        ) && <span>No updates yet</span>}
                                    </div>
                                </div>
                                {Boolean(notebook.fbasummary_modified_zulu) && (
                                    <span
                                        className={styles['download-pdf']}
                                        onClick={
                                            Boolean(
                                                notebook.fbasummary_modified_zulu
                                            )
                                                ? () => {
                                                      window.open(
                                                          `${process.env.REACT_APP_API_ROOT}/api/v1/pdf/case/${notebook.case}/fba-summary?auth=${token}`
                                                      );
                                                  }
                                                : () => null
                                        }
                                    >
                                        <DownloadPDF />
                                    </span>
                                )}
                            </div>
                            <div
                                className={`${styles.document} ${
                                    Boolean(
                                        notebook.interventionplan_modified_zulu
                                    )
                                        ? styles.active
                                        : ''
                                }`}
                            >
                                <div className={styles['document-info']}>
                                    <div className={styles.title}>
                                        Intervention Plan
                                    </div>
                                    <div className={styles.modified}>
                                        {Boolean(
                                            notebook.interventionplan_modified_zulu
                                        ) && (
                                            <span className={styles.date}>
                                                <strong>Last update:</strong>{' '}
                                                {formatDate(
                                                    notebook.interventionplan_modified_zulu
                                                )}
                                            </span>
                                        )}
                                        {!Boolean(
                                            notebook.interventionplan_modified_zulu
                                        ) && <span>No updates yet</span>}
                                    </div>
                                </div>
                                {Boolean(
                                    notebook.interventionplan_modified_zulu
                                ) && (
                                    <span
                                        className={styles['download-pdf']}
                                        onClick={
                                            Boolean(
                                                notebook.interventionplan_modified_zulu
                                            )
                                                ? () => {
                                                      window.open(
                                                          `${process.env.REACT_APP_API_ROOT}/api/v1/pdf/case/${notebook.case}/intervention-plan?auth=${token}`
                                                      );
                                                  }
                                                : () => null
                                        }
                                    >
                                        <DownloadPDF />
                                    </span>
                                )}
                            </div>
                            {!Boolean(notebook.progresschart_modified_zulu) && (
                                <div className={styles.document}>
                                    <div className={styles['document-info']}>
                                        <div className={styles.title}>
                                            Progress Chart
                                        </div>
                                        <div className={styles.modified}>
                                            <span>No updates yet</span>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {notebook.progress_charts.map((chart) => (
                                <div
                                    className={`${styles.document} ${styles.active}`}
                                    key={chart.name}
                                >
                                    <div className={styles['document-info']}>
                                        <div className={styles.title}>
                                            Progress Chart
                                            {notebook.progress_charts.length >
                                                1 && <>&mdash; {chart.name}</>}
                                        </div>
                                        <div className={styles.modified}>
                                            <span className={styles.date}>
                                                <strong>Last update:</strong>{' '}
                                                {formatDate(
                                                    notebook.progresschart_modified_zulu
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                    <span
                                        className={styles['download-pdf']}
                                        onClick={
                                            Boolean(
                                                notebook.progresschart_modified_zulu
                                            )
                                                ? () => {
                                                      window.open(
                                                          `${process.env.REACT_APP_API_ROOT}${chart.url}&auth=${token}`
                                                      );
                                                  }
                                                : () => null
                                        }
                                    >
                                        <DownloadPDF />
                                    </span>
                                </div>
                            ))}
                        </section>
                    )}
                    {attachmentCount > 0 && (
                        <section className={styles.attachments}>
                            <h2 className={styles['attachments-images']}>
                                Attachments & Images ({attachmentCount})
                            </h2>

                            <ul className={styles['attachment-list']}>
                                {attachments
                                    .filter(
                                        (attachment) =>
                                            !isImage(attachment.document_url)
                                    )
                                    .map((attachment) => (
                                        <li
                                            className={styles.attachment}
                                            key={attachment.document_url}
                                        >
                                            <div className={styles.name}>
                                                {attachment.original_filename}
                                            </div>
                                            <span
                                                className={
                                                    styles[
                                                        'download-attachment'
                                                    ]
                                                }
                                                onClick={() =>
                                                    window.open(
                                                        `${process.env.REACT_APP_API_ROOT}${attachment.document_url}?auth=${token}`
                                                    )
                                                }
                                            >
                                                <Download />
                                            </span>
                                        </li>
                                    ))}
                            </ul>

                            <div className={styles.imageAttachments}>
                                {attachments
                                    .filter(
                                        (attachment) =>
                                            isImage(attachment.document_url) &&
                                            !attachment.is_student_portrait
                                    )
                                    .map((attachment) => (
                                        <div key={attachment.document_url}>
                                            <img
                                                className={
                                                    styles[
                                                        'download-image-attachment'
                                                    ]
                                                }
                                                src={`${process.env.REACT_APP_API_ROOT}${attachment.thumbnail_url}?auth=${token}`}
                                                alt={
                                                    attachment.original_filename
                                                }
                                                onClick={() =>
                                                    window.open(
                                                        `${process.env.REACT_APP_API_ROOT}${attachment.document_url}?auth=${token}`
                                                    )
                                                }
                                            />
                                        </div>
                                    ))}
                            </div>
                        </section>
                    )}

                    {family.length === 0 && (
                        <section className={styles['delete-notebook']}>
                            {!confirmDelete && (
                                <button onClick={() => setConfirmDelete(true)}>
                                    Delete Notebook
                                </button>
                            )}

                            {confirmDelete && (
                                <div className={styles['modal-wrapper']}>
                                    <div className={styles['confirm-remove']}>
                                        <div
                                            onClick={() =>
                                                setConfirmDelete(false)
                                            }
                                            className={styles.remove}
                                        >
                                            <CloseX />
                                        </div>
                                        <p>
                                            Are you sure you want to delete this
                                            notebook?
                                        </p>
                                        <div className={styles['button-row']}>
                                            <button
                                                className={styles.cancel}
                                                onClick={() =>
                                                    setConfirmDelete(false)
                                                }
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => deleteNotebook()}
                                            >
                                                Confirm
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </section>
                    )}
                </div>
            </Route>
        </Switch>
    );
};

export default StudentProfile;
