import React from "react";

const CloseX = () => {
    return (
        <svg
            width='8'
            height='8'
            viewBox='0 0 8 8'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M0.258658 0.25866C0.603535 -0.086219 1.16269 -0.0862203 1.50757 0.258658L4.00001 2.75108L6.49242 0.25866C6.8373 -0.0862191 7.39646 -0.0862202 7.74134 0.258658C8.08622 0.603536 8.08622 1.16269 7.74134 1.50757L5.24893 4L7.74134 6.49243C8.08622 6.83731 8.08622 7.39646 7.74134 7.74134C7.39646 8.08622 6.8373 8.08622 6.49242 7.74134L4.00001 5.24892L1.50757 7.74134C1.16269 8.08622 0.603536 8.08622 0.258658 7.74134C-0.0862202 7.39646 -0.0862191 6.8373 0.25866 6.49242L2.7511 4L0.25866 1.50758C-0.086219 1.1627 -0.0862203 0.60354 0.258658 0.25866Z'
            />
        </svg>
    );
};

export default CloseX;
