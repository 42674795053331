import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Store from 'state/store';
import rootReducer from 'state/reducers';
import Login from 'Pages/Login';
import ForgotPassword from 'Pages/ForgotPassword';
import Register from 'Pages/Register';
import PreRegister from 'Pages/PreRegister';
import Notebooks from 'Pages/Notebooks';
import Profile from 'Pages/Profile';
import UserMonitor from 'lib/userMonitor';
import './App.css';

function App() {
    return (
        <Store rootReducer={rootReducer}>
            <Router>
                <UserMonitor />
                <Switch>
                    <Route path='/notebooks'>
                        <Notebooks />
                    </Route>
                    <Route path='/login'>
                        <Login />
                    </Route>
                    <Route path='/forgot-password'>
                        <ForgotPassword />
                    </Route>
                    <Route path='/preregister/:token'>
                        <PreRegister />
                    </Route>
                    <Route path='/register/:token'>
                        <Register />
                    </Route>
                    <Route path='/profile'>
                        <Profile showBack={false} />
                    </Route>
                </Switch>
            </Router>
        </Store>
    );
}

export default App;
