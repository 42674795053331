// @flow
import React, { useReducer } from 'react';
import StoreContext from 'state/context/store';

const Store = (props) => {
  const { rootReducer, children, initialValue } = props;
  const initialState = props.rootReducer(initialValue, { type: '__INIT__' });
  const [state, dispatch] = useReducer(rootReducer, initialState);

  return (
    <StoreContext.Provider value={[state, dispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export default Store;
