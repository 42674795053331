import React from "react";

const PlusSmall = () => {
    return (
        <svg
            width='10'
            height='10'
            viewBox='0 0 10 10'
            fill='currentColor'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M5 0C5.41128 0 5.74468 0.333405 5.74468 0.744681V4.25532H9.25532C9.66659 4.25532 10 4.58872 10 5C10 5.41128 9.66659 5.74468 9.25532 5.74468H5.74468V9.25532C5.74468 9.66659 5.41128 10 5 10C4.58872 10 4.25532 9.66659 4.25532 9.25532V5.74468H0.744681C0.333405 5.74468 0 5.41128 0 5C0 4.58872 0.333405 4.25532 0.744681 4.25532H4.25532V0.744681C4.25532 0.333405 4.58872 0 5 0Z'
            />
        </svg>
    );
};

export default PlusSmall;
