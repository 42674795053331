import { useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getMe, setToken } from 'state/actions/me';
import { getIcons } from 'state/actions/icons';
import StoreContext from 'state/context/store';

const UserMonitor = () => {
    const history = useHistory();
    const location = useLocation();
    const [store, dispatch] = useContext(StoreContext);
    const { me } = store;
    const { authenticated, token } = me;
    const { pathname } = location;

    useEffect(() => {
        // Get Cookie
        const jwtCookie = document.cookie.match(new RegExp('(^| )jwt=([^;]+)'));
        if (jwtCookie) {
            setToken(dispatch, jwtCookie[2]);
            // Delete cookie
            document.cookie = 'jwt=; path=/; max-age=1; domain=ibesttapp.org';
        }
    }, [dispatch]);

    useEffect(() => {
        // Load token from local storage on first page load
        const storedToken = localStorage.getItem('token');
        if (!token && storedToken) {
            setToken(dispatch, storedToken);
        }
    }, [dispatch, token]);

    useEffect(() => {
        // Refetch me whenever token changes
        if (token) {
            getMe(dispatch, token);
        }
    }, [dispatch, token]);

    useEffect(() => {
        // TODO Check user type and direct accordingly
        // Login page should redirect to journal if already authenticated
        if (authenticated && pathname === '/login') {
            const from = location?.state?.from?.pathname;
            history.replace(from ? from : '/notebooks');
            // Home page is login when unauthenticated
        } else if (!authenticated && pathname === '/') {
            history.replace('/login');
            // Home page is journal if already authenticated
        } else if (authenticated && pathname === '/') {
            history.replace('/notebooks');
        }
    }, [pathname, authenticated, location, history]);

    useEffect(() => {
        if (authenticated) {
            getIcons(dispatch, token);
        }
    }, [authenticated, dispatch, token]);

    return null;
};

export default UserMonitor;
