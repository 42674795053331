import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import BackButton from 'Components/BackButton';
import ibesttFamilyLogo from 'assets/images/family-at-ibestt-logo.png';
import styles from './index.module.scss';

const Header = ({
    name,
    showBack,
    showName = true,
    showProfileArrow = true,
    showSubHeader = true,
    photo,
    showPhoto = false,
}) => {
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();
    const { notebookId } = match.params;
    const back = () => {
        // Strip trailing / if set
        const route = location.pathname.replace(/\/$/, '');
        // Pop the last route
        history.replace(route.substring(0, route.lastIndexOf('/')));
    };
    return (
        <header className={styles['header-container']}>
            <div className={styles.banner}>
                <div
                    className={styles.logo}
                    onClick={() => history.push('/notebooks')}
                >
                    <img src={ibesttFamilyLogo} alt='family @ ibestt' />
                </div>
                {showPhoto && (
                    <>
                        {photo && (
                            <img
                                src={photo}
                                className={styles.portrait}
                                alt={name}
                                onClick={() =>
                                    location.pathname !== '/profile'
                                        ? history.push(
                                              `/notebooks/${notebookId}/profile`
                                          )
                                        : undefined
                                }
                            />
                        )}
                        {!photo && (
                            <div
                                className={styles['portrait-placeholder']}
                                onClick={() =>
                                    location.pathname !== '/profile'
                                        ? history.push(
                                              `/notebooks/${notebookId}/profile`
                                          )
                                        : undefined
                                }
                            ></div>
                        )}
                    </>
                )}

                <div
                    className={styles.menuIcon}
                    onClick={() =>
                        history.push('/profile', {
                            from: history.location.pathname,
                        })
                    }
                ></div>
            </div>
            {showSubHeader && (
                <div
                    className={`${styles.subheader} ${
                        showPhoto ? '' : styles['no-photo']
                    }`}
                >
                    {showBack && <BackButton onClick={back} />}

                    {showName && (
                        <h1
                            className={styles.name}
                            onClick={() =>
                                history.push(`/notebooks/${notebookId}/profile`)
                            }
                        >
                            {name}
                        </h1>
                    )}

                    {showProfileArrow && (
                        <div className={styles['profile-arrow-wrapper']}>
                            <BackButton
                                onClick={() =>
                                    history.push(
                                        `/notebooks/${notebookId}/profile`
                                    )
                                }
                            />
                        </div>
                    )}
                </div>
            )}
        </header>
    );
};

export default Header;
