import React, { useContext, useState } from 'react';
import {
    Switch,
    Route,
    Redirect,
    useRouteMatch,
    useLocation,
    useHistory,
} from 'react-router-dom';
import StoreContext from 'state/context/store';
import Header from 'Components/Header';
import BackButton from 'Components/BackButton';
import { updateMe } from 'state/actions/me';
import styles from '../index.module.scss';

const ChangePassword = () => {
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const [store, dispatch] = useContext(StoreContext);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const { me } = store;
    const { authenticated, token } = me;

    const back = () => {
        if (history.length > 0) {
            history.goBack();
        } else {
            history.replace('/profile');
        }
    };

    const submit = async (e) => {
        e.preventDefault();

        if (newPassword && newPassword === confirmPassword) {
            const response = await updateMe(dispatch, token, {
                password: newPassword,
            });
            const result = await response.json();
            if (response.status !== 200) {
                setError(
                    result.non_field_errors?.[0] ||
                        'There was an error updating your password'
                );
            } else {
                back();
            }
        } else {
            setError('Your password does not match');
        }
    };

    const isSubmittable = newPassword && confirmPassword;

    return (
        <div className={styles.container}>
            <Switch>
                {!authenticated && (
                    <Redirect
                        to={{ pathname: '/login', state: { from: location } }}
                    />
                )}

                <Route path={match.path} exact>
                    <Header showBack={false} showSubHeader={false} />
                    <div className={styles.subheader}>
                        <BackButton onClick={back} />
                        <h1 className={styles.title}>Change Password</h1>
                    </div>
                    <form onSubmit={submit}>
                        <div className={styles.row}>
                            <label>
                                <span className={styles['input-label']}>
                                    Password
                                </span>
                                <input
                                    placeholder='password'
                                    type='password'
                                    autoComplete='new-password'
                                    value={newPassword}
                                    onChange={({ target }) =>
                                        setNewPassword(target.value)
                                    }
                                />
                            </label>
                        </div>
                        <div className={styles.row}>
                            <label>
                                <span className={styles['input-label']}>
                                    Confirm Password
                                </span>
                                <input
                                    placeholder='Confirm Password'
                                    type='password'
                                    autoComplete='new-password'
                                    value={confirmPassword}
                                    onChange={({ target }) =>
                                        setConfirmPassword(target.value)
                                    }
                                />
                            </label>
                        </div>
                        <div className={styles.error}>{error}</div>
                        <div className={styles['button-row']}>
                            <button className={styles.cancel} onClick={back}>
                                Cancel
                            </button>
                            <button
                                className={`
                  ${styles['save-button']}
                  ${isSubmittable ? '' : styles.disabled}
                `}
                                type='submit'
                            >
                                Save Changes
                            </button>
                        </div>
                    </form>
                </Route>
            </Switch>
        </div>
    );
};

export default ChangePassword;
