import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import StoreContext from 'state/context/store';

import { DateTime } from 'luxon';
import Icons from 'Components/Icons';
import moodHappy from 'assets/images/mood-icons/mood-happy.svg';
import moodKindaHappy from 'assets/images/mood-icons/mood-kinda-happy.svg';
import moodKindaMad from 'assets/images/mood-icons/mood-kinda-mad.svg';
import moodMad from 'assets/images/mood-icons/mood-mad.svg';
import moodMedium from 'assets/images/mood-icons/mood-medium.svg';
import styles from './index.module.scss';
import EntryMenu from 'Components/EntryMenu';

const indicatorImageLookup = [
    undefined,
    moodMad,
    moodKindaMad,
    moodMedium,
    moodKindaHappy,
    moodHappy,
];

const Entry = ({
    id,
    user,
    attachments,
    author,
    created_zulu,
    author_type,
    text,
    icons,
    parent_entry,
    entry_set,
    noBorder,
    mood,
    food,
    sleep,
    notebook: notebookId,
    setEntries,
    editNote,
    deleteNote,
    onEntriesPage = true,
    entries,
    status,
}) => {
    const history = useHistory();
    const datetime = DateTime.fromISO(created_zulu);
    const now = DateTime.local();
    const [store] = useContext(StoreContext);
    const { me } = store;
    const { userId } = me;
    const isActiveEntry = status === 'active';

    const displayText = isActiveEntry ? text : 'This reply has been deleted.';

    const formatDate = () => {
        const diff = datetime.diff(now.startOf('day'), 'days').as('days');
        if (diff < 1 && diff > 0) {
            return `Today, ${datetime.toFormat('h:mma')}`;
        } else if (diff < 0 && diff > -1) {
            return `Yesterday, ${datetime.toFormat('h:mma')}`;
        } else if (diff < 0 && diff > -6) {
            return `${datetime.toFormat('EEEE, h:mma')}`;
        }
        return datetime.toFormat('DD, h:mma');
    };

    const openNote = () => {
        if (!Boolean(parent_entry)) {
            history.push(`/notebooks/${notebookId}/entry/${id}`);
        }
    };

    const isReply = Boolean(parent_entry);
    const userOwnsEntry = user === userId;
    return (
        <li
            className={`${styles['entry-preview']} ${
                isReply ? styles['is-reply'] : ''
            }`}
            style={{
                borderBottom: noBorder ? 'none' : '1px solid #dddddd',
                cursor: parent_entry ? 'default' : 'pointer',
            }}
            onClick={isActiveEntry ? openNote : undefined}
        >
            <div className={styles['entry-preview-header']}>
                <div>
                    <h2
                        className={styles['author-name']}
                        style={{
                            color:
                                author_type === 'teacher'
                                    ? 'rgba(214, 142, 36, 1)'
                                    : 'rgba(7, 189, 135, 1)',
                        }}
                    >
                        {author}
                    </h2>
                    <div className={`${styles.row} ${styles.date}`}>
                        {formatDate()}
                    </div>
                </div>
                {isActiveEntry && (
                    <div className={styles['icons-and-actions']}>
                        <Icons
                            entry_set={entry_set}
                            icons={icons}
                            entryId={id}
                            parent_entry={parent_entry}
                            isReply={Boolean(parent_entry)}
                            attachments={attachments?.filter(
                                (attachment) => attachment.entry === id
                            )}
                            setEntries={setEntries}
                            entries={entries}
                            onEntriesPage={onEntriesPage}
                        />
                        {isReply && userOwnsEntry && isActiveEntry && (
                            <EntryMenu
                                editNote={editNote}
                                deleteNote={deleteNote}
                            />
                        )}
                    </div>
                )}
            </div>

            <div
                className={styles.text}
                style={{
                    textOverflow: isReply ? 'initial' : 'ellipsis',
                    whiteSpace: isReply ? 'initial' : 'nowrap',
                }}
            >
                {displayText}
            </div>

            <div className={styles.likert}>
                {Boolean(mood) && (
                    <span>
                        Mood: <img src={indicatorImageLookup[mood]} alt='' />
                    </span>
                )}
                {Boolean(food) && (
                    <span>
                        Food: <img src={indicatorImageLookup[food]} alt='' />
                    </span>
                )}
                {Boolean(sleep) && (
                    <span>
                        Sleep: <img src={indicatorImageLookup[sleep]} alt='' />
                    </span>
                )}
            </div>
        </li>
    );
};

export default Entry;
