import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './index.module.scss';
const Notebook = ({
  name,
  hasNewEntries,
  portrait,
  id,
  studentCase,
  student,
  token,
}) => {
  const history = useHistory();
  const match = useRouteMatch();

  return (
    <li
      className={styles['student-notebook']}
      onClick={() => history.push(`${match.path}/${id}`)}
    >
      {Boolean(portrait) && (
        <img
          className={styles.portrait}
          src={`${process.env.REACT_APP_API_ROOT}${portrait}?auth=${token}`}
          alt={name}
        />
      )}
      {!Boolean(portrait) && <div className={styles.portraitPlaceholder} />}
      <h2 className={styles.name}>{name}</h2>
      {hasNewEntries && <div className={styles.newIndicator} />}
    </li>
  );
};

export default Notebook;
