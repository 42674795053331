import React, { useContext, useEffect, useRef, useState } from 'react';
import StoreContext from 'state/context/store';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from './index.module.scss';
import moodHappy from 'assets/images/mood-icons/mood-happy.svg';
import moodKindaHappy from 'assets/images/mood-icons/mood-kinda-happy.svg';
import moodKindaMad from 'assets/images/mood-icons/mood-kinda-mad.svg';
import moodMad from 'assets/images/mood-icons/mood-mad.svg';
import moodMedium from 'assets/images/mood-icons/mood-medium.svg';
import CloseX from 'Components/SvgIcons/CloseX/close-x';
import Paperclip from 'Components/SvgIcons/Paperclip/paperclip';

const indicatorImageLookup = [
    undefined,
    moodMad,
    moodKindaMad,
    moodMedium,
    moodKindaHappy,
    moodHappy,
];

const indicatorLabelLookup = [
    undefined,
    'Mad',
    'Kind of Mad',
    'Neutral',
    'Kind of Happy',
    'Happy',
];

const NewNote = ({
    entries,
    notebook,
    setEntries,
    uploadedAttachments = [],
    setUploadedAttachments,
}) => {
    const history = useHistory();
    const match = useRouteMatch();
    const [store] = useContext(StoreContext);
    const { me } = store;
    const { token, userId } = me;
    const noteRef = useRef();
    const indicatorSelector = useRef();
    const [text, setText] = useState('');
    const [mood, setMood] = useState();
    const [sleep, setSleep] = useState();
    const [meals, setMeals] = useState();
    const [attachments, setAttachments] = useState([]);
    const [attachmentError, setAttachmentError] = useState('');
    const [saveClicked, setSaveClicked] = useState(false);

    const { student_first_name, id: notebookId } = notebook;
    const { entryId } = match.params;

    const saveEnabled =
        (!saveClicked && text.length > 0) ||
        mood ||
        sleep ||
        meals ||
        attachments.length;

    useEffect(() => {
        if (noteRef.current) {
            noteRef.current.focus();
        }
    }, []);

    useEffect(() => {
        if (entryId) {
            const entry = entries.find(
                (entry) =>
                    entry.notebook === notebookId &&
                    entry.id === parseInt(entryId, 10)
            );
            if (entry) {
                setText(entry.text);
                setMeals(entry.food);
                setMood(entry.mood);
                setSleep(entry.sleep);
            }
        }
    }, [notebookId, entries, entryId]);

    const saveNote = async () => {
        if (!saveEnabled) return;
        setSaveClicked(true);
        const response = await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/family/entry${
                entryId ? `/${entryId}` : ''
            }`,
            {
                method: Boolean(entryId) ? 'PUT' : 'POST',
                headers: {
                    Authorization: `jwt ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    notebook: notebook.id,
                    parent_entry: null,
                    user: userId,
                    text,
                    mood,
                    sleep,
                    food: meals,
                }),
            }
        );

        const result = await response.json();
        console.log('result', result);
        console.log('entryId', entryId);
        console.log('entries', entries);
        if (response.status === 201 || response.status === 200) {
            if (entryId) {
                const entryIndex = entries.findIndex(
                    (entry) => entry.id === parseInt(entryId, 10)
                );
                setEntries((entries) => {
                    const updatedEntries = [...entries];
                    updatedEntries[entryIndex] = result;
                    return updatedEntries;
                });
            } else {
                setEntries((entries) => [result, ...entries]);
            }

            attachments.forEach(async (attachment) => {
                const formData = new FormData();
                formData.append('document', attachment);
                formData.append('user', userId);
                formData.append('notebook', notebookId);
                formData.append('is_student_portrait', false);
                formData.append('entry', result.id);

                const response = await fetch(
                    `${process.env.REACT_APP_API_ROOT}/api/family/attachment`,
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `jwt ${token}`,
                        },
                        body: formData,
                    }
                );
                const attachmentResult = await response.json();
                setUploadedAttachments((uploadedAttachments) => [
                    ...uploadedAttachments,
                    attachmentResult,
                ]);
            });
        }

        history.replace(
            `/notebooks/${notebookId}/entry/${
                Boolean(entryId) ? entryId : result.id
            }`
        );
    };

    const isImage = (filename) => {
        if (filename === undefined) {
            return false;
        }
        return filename.toLowerCase().match(/.(jpg|jpeg|png|gif|webp)$/i);
    };

    const addAttachment = async ({ target }) => {
        setAttachmentError('');
        const { files } = target;
        const file = files?.[0];
        if (file) {
            if (file.size > 1e7) {
                // ~10mb
                setAttachmentError('Image size limit is 10MB');
            } else {
                setAttachments([...attachments, file]);
            }
        }
    };

    const deleteAttachment = async (attachment) => {
        const formData = new FormData();
        formData.append('status', 'deleted');
        // formData.append('document', attachment.document_url);
        formData.append('user', userId);
        formData.append('notebook', notebookId);
        formData.append('is_student_portrait', false);
        formData.append('entry', attachment.entry);

        await fetch(
            `${process.env.REACT_APP_API_ROOT}/api/family/attachment/${attachment.id}`,
            {
                method: 'PATCH',
                headers: {
                    Authorization: `jwt ${token}`,
                },
                body: formData,
            }
        );
        setUploadedAttachments(
            uploadedAttachments.filter(
                (uploadedAttachemnt) => uploadedAttachemnt.id !== attachment.id
            )
        );
    };

    const resizeText = ({ target }) => {
        target.style.height = 'inherit';
        target.style.height = `${target.scrollHeight + 3}px`;
    };

    const rateIndicator = (value) => {
        const indicator = indicatorSelector.current.value;

        if (indicator === 'mood') {
            setMood(value);
        } else if (indicator === 'meals') {
            setMeals(value);
        } else if (indicator === 'sleep') {
            setSleep(value);
        }
    };

    return (
        <div className={styles.container} key={entryId}>
            <form>
                <div className={styles.topControls}>
                    <span
                        className={styles.cancel}
                        onClick={() => history.goBack()}
                    >
                        Cancel
                    </span>
                    <label className={styles['upload-button']}>
                        <input type='file' onChange={addAttachment} />
                        <Paperclip />
                    </label>
                    <div
                        className={`${styles.save} ${
                            saveEnabled ? styles.active : ''
                        }`}
                        onClick={saveNote}
                    />
                </div>

                <div className={styles.text}>
                    <textarea
                        ref={noteRef}
                        onChange={({ target }) => setText(target.value)}
                        placeholder='Write your notebook entry here...'
                        onInput={resizeText}
                        value={text}
                    />
                </div>

                <div className={styles['selected-indicators']}>
                    {Boolean(mood) && (
                        <div className={styles['selected-indicator']}>
                            <div
                                className={styles.remove}
                                onClick={() => setMood(null)}
                            >
                                <CloseX />
                            </div>
                            Mood:{' '}
                            <img
                                src={indicatorImageLookup[mood]}
                                alt={indicatorLabelLookup[mood]}
                            />
                        </div>
                    )}
                    {Boolean(sleep) && (
                        <div className={styles['selected-indicator']}>
                            <div
                                className={styles.remove}
                                onClick={() => setSleep(null)}
                            >
                                <CloseX />
                            </div>
                            Sleep:{' '}
                            <img
                                src={indicatorImageLookup[sleep]}
                                alt={indicatorLabelLookup[sleep]}
                            />
                        </div>
                    )}
                    {Boolean(meals) && (
                        <div className={styles['selected-indicator']}>
                            <div
                                className={styles.remove}
                                onClick={() => setMeals(null)}
                            >
                                <CloseX />
                            </div>
                            Meals:{' '}
                            <img
                                src={indicatorImageLookup[meals]}
                                alt={indicatorLabelLookup[meals]}
                            />
                        </div>
                    )}
                </div>

                <div className={styles.error}>{attachmentError}</div>
                <div className={styles.attachments}>
                    {uploadedAttachments
                        .filter(
                            (attachment) =>
                                isImage(attachment.document_url) &&
                                attachment.entry === parseInt(entryId, 10)
                        )
                        .map((attachment) => {
                            // TODO Check for memory leak
                            return (
                                <div
                                    className={styles['image-container']}
                                    key={attachment.name}
                                >
                                    <div
                                        className={styles.remove}
                                        onClick={() =>
                                            deleteAttachment(attachment)
                                        }
                                    >
                                        <CloseX />
                                    </div>
                                    <img
                                        className={styles.attachmentImage}
                                        src={`${process.env.REACT_APP_API_ROOT}${attachment.document_url}?auth=${token}`}
                                        alt={attachment.name}
                                    />
                                </div>
                            );
                        })}
                    {attachments
                        .filter((attachment) => isImage(attachment.name))
                        .map((attachment) => {
                            // TODO Check for memory leak
                            const src = URL.createObjectURL(attachment);
                            return (
                                <div
                                    className={styles['image-container']}
                                    key={attachment.name}
                                >
                                    <div
                                        className={styles.remove}
                                        onClick={() =>
                                            setAttachments(
                                                attachments.filter(
                                                    (a) =>
                                                        a.name !==
                                                        attachment.name
                                                )
                                            )
                                        }
                                    >
                                        <CloseX />
                                    </div>
                                    <img
                                        className={styles.attachmentImage}
                                        src={src}
                                        alt={attachment.name}
                                    />
                                </div>
                            );
                        })}
                    {attachments
                        .filter((attachment) => !isImage(attachment.name))
                        .map((attachment) => (
                            <div
                                className={styles.attachment}
                                key={attachment.name}
                            >
                                <div
                                    className={styles.remove}
                                    onClick={() =>
                                        setAttachments(
                                            attachments.filter(
                                                (a) =>
                                                    a.name !== attachment.name
                                            )
                                        )
                                    }
                                >
                                    <CloseX />
                                </div>
                                <div className={styles.name}>
                                    {attachment.name}
                                </div>
                            </div>
                        ))}
                    {uploadedAttachments
                        .filter(
                            (attachment) =>
                                !isImage(attachment.original_filename) &&
                                attachment.entry === parseInt(entryId, 10)
                        )
                        .map((attachment) => (
                            <div
                                className={styles.attachment}
                                key={attachment.original_filename}
                            >
                                <div
                                    className={styles.remove}
                                    onClick={() => deleteAttachment(attachment)}
                                >
                                    <CloseX />
                                </div>
                                <div className={styles.name}>
                                    {attachment.original_filename}
                                </div>
                            </div>
                        ))}
                </div>

                <div className={styles.indicators}>
                    <select ref={indicatorSelector}>
                        <option value='mood'>{`${student_first_name}'s mood`}</option>
                        <option value='meals'>{`Meals / Snacks`}</option>
                        <option value='sleep'>{`Sleep`}</option>
                    </select>
                    <div className={styles.icons}>
                        <span className={styles['icon-wrap']}>
                            <img
                                src={moodMad}
                                alt='mad'
                                onClick={() => rateIndicator(1)}
                            />
                        </span>
                        <span className={styles['icon-wrap']}>
                            <img
                                src={moodKindaMad}
                                alt='kind of mad'
                                onClick={() => rateIndicator(2)}
                            />
                        </span>
                        <span className={styles['icon-wrap']}>
                            <img
                                src={moodMedium}
                                alt='neutral'
                                onClick={() => rateIndicator(3)}
                            />
                        </span>
                        <span className={styles['icon-wrap']}>
                            <img
                                src={moodKindaHappy}
                                alt='kind of happy'
                                onClick={() => rateIndicator(4)}
                            />
                        </span>
                        <span className={styles['icon-wrap']}>
                            <img
                                src={moodHappy}
                                alt='happy'
                                onClick={() => rateIndicator(5)}
                            />
                        </span>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NewNote;
